import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Section from '@components/Section';
import Technology from './Technology';

function Technologies() {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    {
      allIcons: allFile(
        filter: {
          absolutePath: { regex: "/technologies/" }
          extension: { in: ["png", "jpeg", "jpg"] }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              id
              fixed(height: 40, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const findIcon = path => {
    const edge = data.allIcons.edges.find(
      img => img.node.relativePath === path
    );
    return edge?.node?.childImageSharp?.fixed ?? {};
  };

  return (
    <Section>
      <Container>
        <Typography align="center" variant="h2" gutterBottom>
          Technologies / tools I use
        </Typography>
        <div className={classes.grid}>
          <div className={classes.hide} />
          <div>
            <Technology fixed={findIcon('technologies/go.png')} name="Go" />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/js.png')}
              name="JavaScript"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/typescript.png')}
              name="TypeScript"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/node.png')}
              name="Node.js"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/nest.png')}
              name="NestJS"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/react.png')}
              name="React"
            />
          </div>
          <div className={classes.hide} />

          <div>
            <Technology
              fixed={findIcon('technologies/postgresql.png')}
              name="PostgreSQL"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/redis.png')}
              name="Redis"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/rmq.png')}
              name="RabbitMQ"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology fixed={findIcon('technologies/git.png')} name="Git" />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/graphql.png')}
              name="GraphQL"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/kubernetes.png')}
              name="Kubernetes"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/docker.png')}
              name="Docker"
            />
          </div>

          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/terraform.png')}
              name="Terraform"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/ansible.png')}
              name="Ansible"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/prometheus.png')}
              name="Prometheus"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/grafana.jpg')}
              name="Grafana"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/jaeger.png')}
              name="Jaeger"
            />
          </div>
          <div className={classes.hide} />
          <div>
            <Technology
              fixed={findIcon('technologies/sentry.png')}
              name="Sentry"
            />
          </div>
        </div>
      </Container>
    </Section>
  );
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(13, minmax(0, 1fr));`,
    gap: theme.spacing(1, 1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(6, minmax(0, 1fr));`,
      '& > div:last-child': {
        gridColumnStart: '3',
        gridColumnEnd: 'span 2',
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
          width: `calc(50% - ${theme.spacing(1)}px)`,
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: `repeat(3, minmax(0, 1fr));`,
      '& > div:last-child': {
        display: 'block',
        gridColumnStart: '2',
        gridColumnEnd: '2',
        '& > div': {
          width: 'auto',
        },
      },
    },
  },
  hide: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default Technologies;
